import { majorAwardsCommittees } from '@/helpers/officials';
import { sortByOrder, sortByProperty } from '@/helpers/sort';

export const gridDefaultColDef = {
  resizable: true,
  sortable: true,
};

export function gridColumns(ProfileLink) {
  return [
    {
      headerName: 'Award',
      flex: 1,
      sortable: false,
      sortIndex: 0,
      sort: 'asc',
      autoHeight: true,
      valueGetter: (params) => {
        const { positions } = params.data;

        return positions[0].parent.name;
      },
      cellRenderer: (params) => {
        const { positions } = params.data;

        return positions
          .map((p) => `<div>${p.parent.name}</div>`)
          .join('');
      },
      comparator: (valueA, valueB) => sortByOrder(valueA, valueB, majorAwardsCommittees),
    },
    {
      headerName: 'Role',
      flex: 1,
      sortable: false,
      sortIndex: 1,
      sort: 'asc',
      cellRenderer: (params) => {
        const { positions } = params.data;

        return positions
          .map((p) => `<div>${p.name}</div>`)
          .join('');
      },
      valueGetter: (params) => {
        const { positions } = params.data;

        return positions[0].name;
      },
      comparator: (valueA, valueB) => sortByOrder(valueA, valueB, ['Selection Committee Chair', 'Selection Committee Member']),
    },
    {
      headerName: 'Full Name',
      flex: 1,
      sort: 'asc',
      sortIndex: 2,
      sortable: false,
      valueGetter: (params) => params.data,
      cellRenderer: ProfileLink,
      comparator: (valueA, valueB) => sortByProperty(valueA, valueB, 'surname'),
    },
  ];
}

export function gridRowStyle(params) {
  let previousNode = null;

  // Get Previous Node
  if (params.node.rowIndex > 0) {
    // Get the previous row node
    previousNode = params.api.getDisplayedRowAtIndex(params.node.rowIndex - 1);

    if (previousNode.data.positions[0].parent.name !== params.data.positions[0].parent.name) {
      return {
        borderTop: '2px solid black',
      };
    }
  }

  return undefined;
}
