// eslint-disable-next-line import/prefer-default-export
export const sortByOrder = (valueA, valueB, values) => {
  const sortOrder = values;

  let indexA = sortOrder.indexOf(valueA);
  let indexB = sortOrder.indexOf(valueB);

  if (indexA === -1) indexA = sortOrder.length;
  if (indexB === -1) indexB = sortOrder.length;

  return indexA - indexB;
};

export const sortByProperty = (valueA, valueB, property) => {
  let valuePropA = valueA[property];
  let valuePropB = valueB[property];

  if (typeof valuePropA === 'string' && typeof valuePropB === 'string') {
    valuePropA = valuePropA.toLowerCase();
    valuePropB = valuePropB.toLowerCase();
  }

  if (valuePropA === valuePropB) return 0;
  return (valuePropA > valuePropB) ? 1 : -1;
};

export const sortByTriennialDesc = (valueA, valueB) => {
  // Sort Positions by Triennium descending
  const positionsA = valueA.positions.sort((a, b) => b.triennium.number - a.triennium.number);
  const positionsB = valueB.positions.sort((a, b) => b.triennium.number - a.triennium.number);

  // Get First Position
  const triennialNumberA = positionsA?.[0].triennium.number;
  const triennialNumberB = positionsB?.[0].triennium.number;

  // Sort Descending
  if (triennialNumberA === triennialNumberB) return 0;
  return (triennialNumberA > triennialNumberB) ? 1 : -1;
};
