export const giorgioQuazzaMedalMajorMedal = 'Giorgio Quazza Medal (Major Medal)';
export const nathanielBNicholsMedalMajorMedal = 'Nathaniel B. Nichols Medal (Major Medal)';
export const manfredThomaMedalMajorMedal = 'Manfred Thoma Medal (Major Medal)';
export const industrialAchievementAward = 'Industrial Achievement Award';
export const highImpactPaperAward = 'High Impact Paper Award';
export const haroldChestnutControlEngineeringTextbookPrize = 'Harold Chestnut Control Engineering Textbook Prize';

export const majorAwardsCommittees = [
  giorgioQuazzaMedalMajorMedal,
  nathanielBNicholsMedalMajorMedal,
  manfredThomaMedalMajorMedal,
  industrialAchievementAward,
  highImpactPaperAward,
  haroldChestnutControlEngineeringTextbookPrize,
];

export const executiveOfficersOrder = [
  'President',
  'President-Elect',
  'Immediate Past President',
  'Vice-President for Technical Activities',
  'Vice-President for Conferences',
  'Vice-President for Publications',
  'Vice-President for Finances',
  'Vice-President for Operations',
];
